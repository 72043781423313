import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import PrimeVue from 'primevue/config';
import store from "./store";
import globalComponents from "./global-components";
import utils from "./utils";
import "./libs";

//Prime Vue
import AutoComplete from 'primevue/autocomplete';
import Column from 'primevue/column';
import DataTable from 'primevue/datatable';
import Dialog from 'primevue/dialog';
import Dropdown from 'primevue/dropdown';
import Fieldset from 'primevue/fieldset';
import FileUpload from 'primevue/fileupload';
import Toast from 'primevue/toast';
import ToastService from 'primevue/toastservice';
import InputText from 'primevue/inputtext';
import Textarea from 'primevue/textarea';
import Accordion from 'primevue/accordion';
import AccordionTab from 'primevue/accordiontab';
import Button from 'primevue/button';
//Fin Prime Vue

// SASS Theme
import "./assets/sass/app.scss";
//import 'primevue/resources/themes/saga-blue/theme.css';
import 'primevue/resources/primevue.min.css';
import 'primeicons/primeicons.css';

const app = createApp(App)
  .use(store)
  .use(router);

globalComponents(app);
utils(app);

//Prime Vue
app.use(PrimeVue);
app.use(ToastService);
app.component('AutoComplete', AutoComplete);
app.component('Fieldset', Fieldset);
app.component('Dropdown', Dropdown);
app.component('DataTable', DataTable);
app.component('Column', Column);
app.component('Dialog', Dialog);
app.component('FileUpload', FileUpload);
app.component('Toast', Toast);
app.component('InputText', InputText);
app.component('Textarea', Textarea);
app.component('Accordion', Accordion);
app.component('AccordionTab', AccordionTab);
app.component('Button', Button);
//Fin Prime Vue

app.mount("#app");
