<template>
  <div>
    <DarkModeSwitcher />
    <div class="container sm:px-10">
      <div class="block xl:grid grid-cols-2 gap-4">
        <!-- BEGIN: Login Info -->
        <div class="hidden xl:flex flex-col min-h-screen">
          <a href="" class="-intro-x flex items-center pt-5">
            <img
              alt="Icewall Tailwind HTML Admin Template"
              :src="require(`@/assets/images/Sarvi.png`)"
              style="width:13rem;"
            />
          </a>
          <div class="my-auto">
            <img
              alt="Icewall Tailwind HTML Admin Template"
              class="-intro-x w-1/2 -mt-16"
              style="margin: 0px 0px 10px 0px;
              background: #fbd425;
              border-radius: 20px;
              padding: 5px;"
              :src="require(`@/assets/images/login.jpeg`)"
            />
            <div
              class="-intro-x text-white font-medium text-4xl leading-tight mt-10"
            >
              ¡Gestionar y controlar tu  <br />
              operación logística ahora <br />
              es más fácil!
            </div>
          </div>
        </div>
        <!-- END: Login Info -->
        <!-- BEGIN: Login Form -->
        <div class="h-screen xl:h-auto flex py-5 xl:py-0 my-10 xl:my-0">
          <div
            class="my-auto mx-auto xl:ml-20 bg-white dark:bg-dark-1 xl:bg-transparent px-5 sm:px-8 py-8 xl:p-0 rounded-md shadow-md xl:shadow-none w-full sm:w-3/4 lg:w-2/4 xl:w-auto"
          >
            <form class="validate-form" @submit.prevent="save">
              <h2
                class="intro-x font-bold text-2xl xl:text-3xl text-center xl:text-left"
              >
                Bienvenido a nmvPostal
              </h2>
              <div class="intro-x mt-2 text-gray-500 xl:hidden text-center">
                ¡Gestionar y controlar tu operación logística ahora es más fácil!
              </div>
              <div class="intro-x mt-8">

                <input
                    id="username"
                    v-model.trim="validate.user.$model"
                    type="text"
                    name="username"
                    class="intro-x login__input form-control py-3 px-4 border-gray-300 block"
                    :class="{ 'border-theme-24': validate.user.$error }"
                    placeholder="Nombre de usuario"
                />
                <template v-if="validate.user.$error">
                  <div
                      v-for="(error, index) in validate.user.$errors"
                      :key="index"
                      class="text-theme-24 mt-2"
                  >
                    {{ error.$message }}
                  </div>
                </template>

                <input
                    id="current-password"
                    v-model.trim="validate.password.$model"
                    type="password"
                    name="current-password"
                    class="intro-x login__input form-control py-3 px-4 border-gray-300 block mt-4"
                    :class="{ 'border-theme-24': validate.password.$error }"
                    placeholder="Nombre de usuario"
                />
                <template v-if="validate.password.$error">
                  <div
                      v-for="(error, index) in validate.password.$errors"
                      :key="index"
                      class="text-theme-24 mt-2"
                  >
                    {{ error.$message }}
                  </div>
                </template>

              </div>

              <div
                class="intro-x flex text-gray-700 dark:text-gray-600 text-xs sm:text-sm mt-4"
              >

              </div>
              <div class="intro-x mt-5 xl:mt-8 text-center xl:text-left">
                <button
                  class="btn btn-primary py-3 px-4 w-full xl:w-32 xl:mr-3 align-top"
                  type="submit"
                >
                  Entrar
                </button>
              </div>
              <div
                class="intro-x mt-10 xl:mt-24 text-gray-700 dark:text-gray-600 text-center xl:text-left"
              >
              </div>
            </form>
            <!-- END: Validation Form -->
            <!-- BEGIN: Success Notification Content -->
            <div
                id="success-notification-content"
                class="toastify-content hidden flex"
            >
              <CheckCircleIcon class="text-theme-10" />
              <div class="ml-4 mr-4">
                <div class="font-medium">¡Exito!</div>
                <div class="text-gray-600 mt-1">
                  Datos enviados correctamente. Por favor espere...
                </div>
              </div>
            </div>
            <!-- END: Success Notification Content -->
            <!-- BEGIN: Failed Notification Content -->
            <div
                id="failed-notification-content"
                class="toastify-content hidden flex"
            >
              <XCircleIcon class="text-theme-24" />
              <div class="ml-4 mr-4">
                <div class="font-medium">¡Error!</div>
                <div class="text-gray-600 mt-1">
                  No se ha podido iniciar sesión.
                </div>
              </div>
            </div>
            <!-- END: Failed Notification Content -->
          </div>
        </div>
        <!-- END: Login Form -->
      </div>
    </div>
  </div>
</template>

<script>
import { defineComponent, onMounted, reactive, toRefs } from "vue";
import DarkModeSwitcher from "@/components/dark-mode-switcher/Main.vue";
import axios from "axios";
import { API_LOCAL_URL } from "@/config";
import cash from "cash-dom";
import {
  required,
  minLength,
  helpers
} from "@vuelidate/validators";
import { useVuelidate } from "@vuelidate/core";
import Toastify from "toastify-js";
import { useStore } from "@/store";
import router from "../../router";

export default defineComponent({
  "name": "Login",
  components: {
    DarkModeSwitcher
  },
  setup() {

    onMounted(() => {
      cash("body")
        .removeClass("main")
        .removeClass("error-page")
        .addClass("login");
    });

    const store = useStore();
    store.commit('auth/setAuth');

    const formData = reactive({
      "user": "",
      "password": ""
    });

    const rules = {
      user: {
        required: helpers.withMessage('Este campo es requerido.', required),
        minLength: helpers.withMessage('Este campo debe tener al menos 2 caracteres.', minLength(2))
      },
      password: {
        required: helpers.withMessage('Este campo es requerido.', required),
        minLength: helpers.withMessage('Este campo debe tener al menos 6 caracteres.', minLength(6)),
      }
    };

    const validate = useVuelidate(rules, toRefs(formData));

    const save = () => {
      validate.value.$touch();
      if (validate.value.$invalid) {
        Toastify({
          node: cash("#failed-notification-content")
              .clone()
              .removeClass("hidden")[0],
          duration: 3000,
          newWindow: true,
          close: true,
          gravity: "top",
          position: "right",
          stopOnFocus: true
        }).showToast();
      } else {

        const formDataModel = {
          "username": validate.value.user.$model,
          "password": validate.value.password.$model
        };

        axios
            .post(API_LOCAL_URL+'login', formDataModel)
            .then(response => {
              localStorage.setItem("user", response.data.success.user.username);
              localStorage.setItem("token", response.data.token);
              localStorage.setItem("time",  new Date().getTime());
              localStorage.setItem("auth", true);
              localStorage.setItem("scopes", response.data.roles);
              store.commit('auth/setAuth');
              Toastify({
                node: cash("#success-notification-content")
                    .clone()
                    .removeClass("hidden")[0],
                duration: 3000,
                newWindow: true,
                close: true,
                gravity: "top",
                position: "right",
                stopOnFocus: true
              }).showToast();
              router.push({ name: "orders" });
            })
            .catch(() => {
              Toastify({
                node: cash("#failed-notification-content")
                    .clone()
                    .removeClass("hidden")[0],
                duration: 5000,
                newWindow: true,
                close: true,
                gravity: "top",
                position: "right",
                stopOnFocus: true
              }).showToast();
            });


      }
    };

    return {
      validate,
      formData,
      save
    };

  },
});
</script>
