//export const API_BASE_URL = "http://127.0.0.1:8000/api/rest?{replace}&urlRest=http://dsv.colombiasoftware.co/index.php";
export const API_BASE_URL = "https://sarvi.nmvpostal.com/backend/api/rest?{replace}&urlRest=https://dsv.colombiasoftware.net/index.php";
//export const API_BASE_URL = "https://sarvi.nmvpostal.com/backend/api/rest?{replace}&urlRest=http://dsv.colombiasoftware.co/index.php";

export const API_BASE_BDP_URL = "https://sarvi.nmvpostal.com/backend/api/rest?{replace}&urlRest=https://bdp.colombiasoftware.net/index.php";

//export const API_LOCAL_URL = "http://127.0.0.1:8000/api/";
export const API_LOCAL_URL = "https://sarvi.nmvpostal.com/backend/api/";

//export const API_SYSCOM_URL = "http://127.0.0.1:8000/api/rest?urlRest=http://186.116.2.163:8081/Servicio.svc/rest/";
export const API_SYSCOM_URL = "https://sarvi.nmvpostal.com/backend/api/rest?urlRest=http://186.116.2.163:8081/Servicio.svc/rest/";
//export const API_SYSCOM_URL = "https://sarvi.nmvpostal.com/backend/api/rest?urlRest=http://181.49.40.74:8081/Servicio.svc/rest/";

//export const API_SATRACK_URL = "http://127.0.0.1:8000/api/restxml?{replace}urlRest=https://webcloud.satrack.com/WebServiceEventos/getEvents.asmx?wsdl";
export const API_SATRACK_URL = "https://sarvi.nmvpostal.com/backend/api/restxml?{replace}urlRest=https://webcloud.satrack.com/WebServiceEventos/getEvents.asmx?wsdl";