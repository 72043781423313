<template>
  <div>
    <DarkModeSwitcher />
    <MobileMenu />
    <TopBar />
    <div class="wrapper">
      <div class="wrapper-box">
        <!-- BEGIN: Side Menu -->
        <nav class="side-nav side-nav--simple">
          <ul>
            <!-- BEGIN: First Child -->
            <template v-for="(menu, menuKey) in formattedMenu">
              <li
                v-if="menu == 'devider'"
                :key="menu + menuKey"
                class="side-nav__devider my-6"
              ></li>
              <li v-else :key="menu + menuKey">
                <Tippy
                  tag="a"
                  :content="menu.title"
                  :options="{
                    placement: 'left'
                  }"
                  href="javascript:;"
                  class="side-menu"
                  :class="{
                    'side-menu--active': menu.active,
                    'side-menu--open': menu.activeDropdown
                  }"
                  @click="linkTo(menu, router)"
                >
                  <div class="side-menu__icon">
                    <component :is="menu.icon" />
                  </div>
                  <div class="side-menu__title">
                    {{ menu.title }}
                    <ChevronDownIcon
                      v-if="$h.isset(menu.subMenu)"
                      class="side-menu__sub-icon"
                      :class="{ 'transform rotate-180': menu.activeDropdown }"
                    />
                  </div>
                </Tippy>
                <!-- BEGIN: Second Child -->
                <transition @enter="enter" @leave="leave">
                  <ul v-if="menu.subMenu && menu.activeDropdown">
                    <li
                      v-for="(subMenu, subMenuKey) in menu.subMenu"
                      :key="subMenuKey"
                    >
                      <Tippy
                        tag="a"
                        :content="subMenu.title"
                        :options="{
                          placement: 'left'
                        }"
                        href="javascript:;"
                        class="side-menu"
                        :class="{ 'side-menu--active': subMenu.active }"
                        @click="linkTo(subMenu, router)"
                      >
                        <div class="side-menu__icon">
                          <ActivityIcon />
                        </div>
                        <div class="side-menu__title">
                          {{ subMenu.title }}
                          <ChevronDownIcon
                            v-if="$h.isset(subMenu.subMenu)"
                            class="side-menu__sub-icon"
                            :class="{
                              'transform rotate-180': subMenu.activeDropdown
                            }"
                          />
                        </div>
                      </Tippy>
                      <!-- BEGIN: Third Child -->
                      <transition @enter="enter" @leave="leave">
                        <ul v-if="subMenu.subMenu && subMenu.activeDropdown">
                          <li
                            v-for="(lastSubMenu,
                            lastSubMenuKey) in subMenu.subMenu"
                            :key="lastSubMenuKey"
                          >
                            <Tippy
                              tag="a"
                              :content="lastSubMenu.title"
                              :options="{
                                placement: 'left'
                              }"
                              href="javascript:;"
                              class="side-menu"
                              :class="{
                                'side-menu--active': lastSubMenu.active
                              }"
                              @click="linkTo(lastSubMenu, router)"
                            >
                              <div class="side-menu__icon">
                                <ZapIcon />
                              </div>
                              <div class="side-menu__title">
                                {{ lastSubMenu.title }}
                              </div>
                            </Tippy>
                          </li>
                        </ul>
                      </transition>
                      <!-- END: Third Child -->
                    </li>
                  </ul>
                </transition>
                <!-- END: Second Child -->
              </li>
            </template>
            <!-- END: First Child -->
          </ul>
        </nav>
        <!-- END: Side Menu -->
        <!-- BEGIN: Content -->
        <div class="content">
          <router-view />
        </div>
        <!-- END: Content -->
      </div>
    </div>
  </div>
</template>

<script>
import { defineComponent, computed, onMounted, ref, watch } from "vue";
import { useRoute, useRouter } from "vue-router";
import { useStore } from "@/store";
import { helper as $h } from "@/utils/helper";
import TopBar from "@/components/top-bar/Main.vue";
import MobileMenu from "@/components/mobile-menu/Main.vue";
import DarkModeSwitcher from "@/components/dark-mode-switcher/Main.vue";
import { linkTo, nestedMenu, enter, leave } from "@/layouts/side-menu";

export default defineComponent({
  components: {
    TopBar,
    MobileMenu,
    DarkModeSwitcher
  },
  setup() {
    const route = useRoute();
    const router = useRouter();
    const store = useStore();
    const formattedMenu = ref([]);
    const simpleMenu = computed(() =>
      nestedMenu(store.state.simpleMenu.menu, route)
    );

    watch(
      computed(() => route.path),
      () => {
        formattedMenu.value = $h.toRaw(simpleMenu.value);
      }
    );

    onMounted(() => {
      cash("body")
        .removeClass("error-page")
        .removeClass("login")
        .addClass("main");
      formattedMenu.value = $h.toRaw(simpleMenu.value);
    });

    return {
      formattedMenu,
      router,
      linkTo,
      enter,
      leave
    };
  }
});
</script>
