const state = () => {
    return {
        user: localStorage.getItem('user'),
        token: localStorage.getItem('token'),
        time: localStorage.getItem('time'),
        authenticated: localStorage.getItem('auth'),
        scopes: localStorage.getItem('scopes')
    }
}

// getters
const getters = {};

// actions
const actions = {};

// mutations
const mutations = {
    setAuth (state) {
        state.user = localStorage.getItem('user');
        state.token = localStorage.getItem('token');
        state.time = localStorage.getItem('time');
        state.authenticated = localStorage.getItem('auth');
        state.scopes = localStorage.getItem('scopes');
    }
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
};