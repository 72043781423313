<template>
  <router-view />
</template>
<script>

export default {
  data() {
    return {
      start: null,
      end: null,
      diff: null
    }
  },
  watch:{
    $route (){
      if(this.$store.state.auth.time) {
        this.start = new Date(parseFloat(this.$store.state.auth.time));
        this.end = new Date();
        this.diff = (this.end.valueOf()-this.start.valueOf())/1000/60;
        if(this.diff >= 119){
          this.logoutUser();
        }
      }
    }
  },
  methods: {
    logoutUser() {
      localStorage.clear();
      this.$router.push({ name: "login" });
    }
  },
}
</script>